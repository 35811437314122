import { useConnectedUserData } from 'app/context/connectedUser';
import React, { ComponentType } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';

interface Props extends Omit<RouteProps, 'component' | 'render' | 'children'> {
    component: ComponentType<RouteComponentProps<any>>;
}

const ProtectedRoute = ({ component: Component, ...rest }: Props) => {
    const { data: connectedUserData } = useConnectedUserData();

    return (
        <Route
            {...rest}
            render={(props) => connectedUserData.apiToken ?
                (<Component {...props} />) :
                (<Redirect to={{ pathname: '/login', state: { from: props.location } }}/>)}
        />);
};

export default ProtectedRoute;