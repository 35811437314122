import { Crumb } from 'data/app/Crumb';
import { createCtx } from 'lib/context/context';

export interface BreadcrumbContextValue {
    data: Crumb[];
    setBreadcrumb: (crumbs: Crumb[]) => void;
}

const breadcrumbCtx = createCtx<BreadcrumbContextValue>();

export const useBreadcrumb = breadcrumbCtx[0];
export const BreadcrumbProvider = breadcrumbCtx[1];