import { AuthDataContextValue } from 'app/context/authData';
import { AuthData } from 'data/app/AuthData';
import { useMemo } from 'react';

const useAuthDataContextData = (
    initialAuthData: AuthData
): AuthDataContextValue => {
    return useMemo<AuthDataContextValue>(
        () => ({ data: initialAuthData }),
        [initialAuthData]
    );
};

export default useAuthDataContextData;