import { TypographyHandler } from 'data/app/Theme/Typography/TypographyHandler';
import { ColorIntentionHandler } from './ColorIntention/ColorIntentionHandler';
import { ThemeHeaderApiType } from './Theme.Types';
import { ThemeHeader } from './ThemeHeader';

export class ThemeHeaderHandler {
    public static Instance: ThemeHeaderHandler = new ThemeHeaderHandler();

    private constructor() {
    }

    public fromApi(apiValue: ThemeHeaderApiType): ThemeHeader {
        return ThemeHeader.of(apiValue);
    }

    public toApi(themeHeader: ThemeHeader): ThemeHeaderApiType {
        return {
            name: themeHeader.name,
            identifier: themeHeader.identifier,
            description: themeHeader.description,
            enable: themeHeader.enable,
            primary: ColorIntentionHandler.Instance.toApi(themeHeader.primary),
            secondary: ColorIntentionHandler.Instance.toApi(themeHeader.secondary),
            tertiary: ColorIntentionHandler.Instance.toApi(themeHeader.tertiary),
            quaternary: ColorIntentionHandler.Instance.toApi(themeHeader.quaternary),
            quinquennial: ColorIntentionHandler.Instance.toApi(themeHeader.quinquennial),
            title1: TypographyHandler.Instance.toApi(themeHeader.title1),
            title2: TypographyHandler.Instance.toApi(themeHeader.title2),
            title3: TypographyHandler.Instance.toApi(themeHeader.title3),
            title4: TypographyHandler.Instance.toApi(themeHeader.title4),
            title5: TypographyHandler.Instance.toApi(themeHeader.title5),
            title6: TypographyHandler.Instance.toApi(themeHeader.title6),
            subTitle1: TypographyHandler.Instance.toApi(themeHeader.subTitle1),
            subTitle2: TypographyHandler.Instance.toApi(themeHeader.subTitle2),
            body1: TypographyHandler.Instance.toApi(themeHeader.body1),
            body2: TypographyHandler.Instance.toApi(themeHeader.body2),
            button: TypographyHandler.Instance.toApi(themeHeader.button),
            caption: TypographyHandler.Instance.toApi(themeHeader.caption),
            overline: TypographyHandler.Instance.toApi(themeHeader.overline),
            darkMode: themeHeader.darkMode,
            lightMode: themeHeader.lightMode
        };
    }
}