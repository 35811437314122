import { Theme as MuiTheme } from '@material-ui/core';
import { ThemeContextValue } from 'app/context/theme';
import { PlatformSettingsHeader } from 'data/app/PlatformSettings/PlatformSettings.Types';
import { CalistaTheme } from 'data/app/Theme/Theme.Types';
import { useEffect, useMemo, useState } from 'react';

const useThemeContextData = (
    platformSettings: PlatformSettingsHeader
): [CalistaTheme, ThemeContextValue] => {
    const muiTheme = useMemo(
        () => platformSettings.theme.createMuiTheme(platformSettings.darkMode ? 'dark' : 'light'),
        [platformSettings.theme, platformSettings.darkMode]
    );
    const [theme, setTheme] = useState<MuiTheme>(muiTheme);
    useEffect(
        () => {
            setTheme(muiTheme);
        },
        [muiTheme]
    );

    return useMemo<[CalistaTheme, ThemeContextValue]>(
        () => [theme as CalistaTheme, { changeTheme: (data) => setTheme(data) }],
        [theme, setTheme]
    );
};
export default useThemeContextData;