import DateMomentUtils from '@date-io/moment';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import useHideMenuContextData from 'app/components/context/useHideMenuContextData';
import { HideMenuProvider } from 'app/context/hideMenu';
import ProtectedRoute from 'components/routing/ProtectedRoute';
import { AuthData } from 'data/app/AuthData';
import { CentralError } from 'data/app/CentralError';
import { UserInfo } from 'data/app/ConnectedUser';
import { Crumb } from 'data/app/Crumb';
import { InitialData } from 'data/app/InitialData';
import { PlatformSettingsHeader } from 'data/app/PlatformSettings/PlatformSettings.Types';
import { noop } from 'lib/lang/noop';
import { ButtonListElements } from 'lib/layout/Buttons/ButtonList.Props';
import React from 'react';
import Helmet from 'react-helmet';
import { IntlProvider } from 'react-intl';
import Loadable from 'react-loadable';
import { Route, Switch } from 'react-router-dom';

import './App.css';
import AuthFrame from './Auth/Frame';
import useActionContextData from './components/context/useActionContextData';
import useAppLoadingContextData from './components/context/useAppLoadingContextData';
import useAppSettingsContextData from './components/context/useAppSettingsContextData';
import useAuthDataContextData from './components/context/useAuthDataContextData';
import useBreadcrumbContextData from './components/context/useBreadcrumbContextData';
import useCentralErrorContextData from './components/context/useCentralErrorContextData';
import useConnectedUserContextData from './components/context/useConnectedUserContextData';
import useInitialDataContextData from './components/context/useInitialDataContextData';
import useLocaleSettingsContextData from './components/context/useLocaleSettingsContextData';
import usePlatformSettingsContextData from './components/context/usePlatformSettingsContextData';
import useThemeContextData from './components/context/useThemeContextData';
import SentryErrorBoundary from './components/Error/SentryErrorBoundary';
import { ActionDataProvider } from './context/actionData';
import { AppLoadingProvider } from './context/appLoading';
import { AppSettingsProvider } from './context/appSettings';
import { AuthDataProvider } from './context/authData';
import { BreadcrumbProvider } from './context/breadcrumb';
import { CentralErrorDataProvider } from './context/centralErrorData';
import { ConnectedUserProvider } from './context/connectedUser';
import { InitialDataProvider } from './context/initialData';
import { LocaleSettingsProvider } from './context/localeSettings';
import { PlatformSettingsProvider } from './context/platformSettings';
import { ThemeProvider } from './context/theme';
import FavIcon from './FavIcon';

const HomeAsync = Loadable({
                               loader: () => import('./Home/Home'),
                               loading: () => null
                           });

library.add(fas);

interface Props {
    initialData?: InitialData;
    initialBreadcrumb?: Crumb[];
    forcedBreadcrumb?: boolean;
    initialActions?: ButtonListElements;
    forcedActions?: boolean;
    authData: AuthData;
    userPersistor?: (data: UserInfo | null) => void;
    initialError?: CentralError;
    initialPlatformSettings: PlatformSettingsHeader;
    version: string;
    initialHideMenu?: boolean;
    forcedHideMenu?: boolean;
}

const App = (
    {
        initialData,
        initialBreadcrumb = [],
        forcedBreadcrumb = false,
        initialHideMenu = false,
        forcedHideMenu = false,
        authData,
        initialActions = [],
        forcedActions = false,
        userPersistor = noop,
        initialError,
        initialPlatformSettings,
        version
    }: Props) => {
    const localeSettingsContextValue = useLocaleSettingsContextData();
    const [platformSettings, platformSettingsContextValue] = usePlatformSettingsContextData(initialPlatformSettings);
    const [theme, themeContextValue] = useThemeContextData(platformSettings);
    const connectedUserContextValue = useConnectedUserContextData(authData, userPersistor);
    const authDataContextValue = useAuthDataContextData(authData);
    const initialDataContextValue = useInitialDataContextData(initialData);
    const breadcrumbContextValue = useBreadcrumbContextData(initialBreadcrumb, forcedBreadcrumb);
    const hideMenuContextValue = useHideMenuContextData(initialHideMenu, forcedHideMenu);
    const appLoadingContextValue = useAppLoadingContextData();
    const actionsContextValue = useActionContextData(initialActions, forcedActions);
    const centralErrorDataContextValue = useCentralErrorContextData(initialError);
    const appSettingsContextValue = useAppSettingsContextData(version);

    // noinspection HtmlUnknownTarget
    return (
        <SentryErrorBoundary>
            <Helmet>
                <link rel="manifest" href="/manifest.webmanifest"/>
            </Helmet>
            <FavIcon theme={theme}/>
            <CssBaseline/>
            <ThemeProvider value={themeContextValue}>
                <LocaleSettingsProvider value={localeSettingsContextValue}>
                    <PlatformSettingsProvider value={platformSettingsContextValue}>
                        <ConnectedUserProvider value={connectedUserContextValue}>
                            <AuthDataProvider value={authDataContextValue}>
                                <InitialDataProvider value={initialDataContextValue}>
                                    <BreadcrumbProvider value={breadcrumbContextValue}>
                                        <HideMenuProvider value={hideMenuContextValue}>
                                            <AppLoadingProvider value={appLoadingContextValue}>
                                                <ActionDataProvider value={actionsContextValue}>
                                                    <CentralErrorDataProvider value={centralErrorDataContextValue}>
                                                        <AppSettingsProvider value={appSettingsContextValue}>
                                                            <IntlProvider locale="fr" defaultLocale="fr">
                                                                <MuiThemeProvider theme={theme}>
                                                                    <MuiPickersUtilsProvider utils={DateMomentUtils}>
                                                                        <Switch>
                                                                            <Route path="/login" component={AuthFrame}/>
                                                                            <ProtectedRoute component={HomeAsync}/>
                                                                        </Switch>
                                                                    </MuiPickersUtilsProvider>
                                                                </MuiThemeProvider>
                                                            </IntlProvider>
                                                        </AppSettingsProvider>
                                                    </CentralErrorDataProvider>
                                                </ActionDataProvider>
                                            </AppLoadingProvider>
                                        </HideMenuProvider>
                                    </BreadcrumbProvider>
                                </InitialDataProvider>
                            </AuthDataProvider>
                        </ConnectedUserProvider>
                    </PlatformSettingsProvider>
                </LocaleSettingsProvider>
            </ThemeProvider>
        </SentryErrorBoundary>
    );
};

export default App;
