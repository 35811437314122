import { ConnectedUser } from 'data/app/ConnectedUser';
import { createCtx } from 'lib/context/context';

export interface ConnectedUserContextValue {
    data: ConnectedUser;
    setData: (data: ConnectedUser) => void;
    unsetData: () => void;
}

const connectedUserCtx = createCtx<ConnectedUserContextValue>();

export const useConnectedUserData = connectedUserCtx[0];
export const ConnectedUserProvider = connectedUserCtx[1];