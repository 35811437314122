import { CentralErrorDataContextValue } from 'app/context/centralErrorData';
import { CentralError } from 'data/app/CentralError';
import { useCallback, useMemo, useState } from 'react';

const useCentralErrorContextData = (
    initialError?: CentralError
) => {
    const [error, setError] = useState<CentralError | undefined>(initialError);
    const setData = useCallback(
        (e: CentralError) => setError(e),
        [setError]
    );
    const unsetData = useCallback(
        () => setError(undefined),
        [setError]
    );

    return useMemo<CentralErrorDataContextValue>(
        () => ({
            data: error,
            setData,
            unsetData
        }),
        [error, setData, unsetData]
    );
};

export default useCentralErrorContextData;