import { responsiveFontSizes, Theme as MuiTheme } from '@material-ui/core';
import { frFR } from '@material-ui/core/locale';
import { createMuiTheme as createMuiThemeFn } from '@material-ui/core/styles';
import { Overrides } from '@material-ui/core/styles/overrides';
import { ComponentsProps } from '@material-ui/core/styles/props';
import assets from 'assets/assets';
import { ColorIntentionHandler } from 'data/app/Theme/ColorIntention/ColorIntentionHandler';
import { Typography } from 'data/app/Theme/Typography/Typography.Types';
import { TypographyHandler } from 'data/app/Theme/Typography/TypographyHandler';
import { augmentColor } from 'lib/display/Color';
import { ColorIntention } from './ColorIntention/ColorIntention.Types';
import { CalistaTheme, CalistaThemeFinalOptions, CalistaThemeOptions } from './Theme.Types';

const breakpoints = {
    values: {
        xs: 0,
        sm: 680,
        md: 1020,
        lg: 1400,
        xl: 1850
    }
};

const createOverrides = ({ palette }: CalistaTheme): Overrides => {
    return {
        MuiInputBase: {
            root: {
                '& fieldset': {
                    borderColor: palette.primary.main
                }
            },
            colorSecondary: {
                '& fieldset': {
                    borderColor: palette.secondary.main
                }
            }
        }
    };
};

const createProps = (_: CalistaTheme): ComponentsProps => {
    return {
        MuiDialog: {
            scroll: 'paper'
        }
    };
};

const augmentColors = (finalTheme: CalistaTheme, data: CalistaThemeFinalOptions) => {
    finalTheme.palette.tertiary = augmentColor(
        data.palette.tertiary,
        finalTheme
    );
    finalTheme.palette.quaternary = augmentColor(
        data.palette.quaternary,
        finalTheme
    );
    finalTheme.palette.quinquennial = augmentColor(
        data.palette.quinquennial,
        finalTheme
    );
};

const doCreateTheme = (data: CalistaThemeFinalOptions, makeFontsResponsive: boolean) => {
    const baseTheme = createMuiThemeFn({ breakpoints, ...data }, frFR);
    const finalTheme = (makeFontsResponsive ? responsiveFontSizes(baseTheme) : baseTheme) as CalistaTheme;
    augmentColors(finalTheme, data);
    return finalTheme;
};

export const createMuiCalistaTheme = (data: CalistaThemeFinalOptions, makeFontsResponsive: boolean): CalistaTheme => {
    const finalTheme = doCreateTheme(data, makeFontsResponsive);
    return {
        ...finalTheme,
        overrides: createOverrides(finalTheme),
        props: createProps(finalTheme)
    };
};

export const createMuiTheme = (data: CalistaThemeOptions, makeFontsResponsive: boolean = true): CalistaTheme => {
    return createMuiCalistaTheme({ assets, ...data }, makeFontsResponsive);
};

export abstract class AMuiThemeCreator {
    protected abstract getPrimary(): ColorIntention;

    protected abstract getSecondary(): ColorIntention;

    protected abstract getTertiary(): ColorIntention;

    protected abstract getQuaternary(): ColorIntention;

    protected abstract getQuinquennial(): ColorIntention;

    protected abstract getTitle1(): Typography;

    protected abstract getTitle2(): Typography;

    protected abstract getTitle3(): Typography;

    protected abstract getTitle4(): Typography;

    protected abstract getTitle5(): Typography;

    protected abstract getTitle6(): Typography;

    protected abstract getSubTitle1(): Typography;

    protected abstract getSubTitle2(): Typography;

    protected abstract getBody1(): Typography;

    protected abstract getBody2(): Typography;

    protected abstract getButton(): Typography;

    protected abstract getCaption(): Typography;

    protected abstract getOverline(): Typography;

    public createMuiTheme(type?: 'dark' | 'light'): MuiTheme {
        return createMuiTheme(
            {
                palette: {
                    type,
                    primary: ColorIntentionHandler.Instance.toThemeOptions(this.getPrimary()),
                    secondary: ColorIntentionHandler.Instance.toThemeOptions(this.getSecondary()),
                    tertiary: ColorIntentionHandler.Instance.toThemeOptions(this.getTertiary()),
                    quaternary: ColorIntentionHandler.Instance.toThemeOptions(this.getQuaternary()),
                    quinquennial: ColorIntentionHandler.Instance.toThemeOptions(this.getQuinquennial()),
                },
                typography: {
                    fontFamily: this.getBody1().font,
                    h1: TypographyHandler.Instance.toThemeOptions(this.getTitle1()),
                    h2: TypographyHandler.Instance.toThemeOptions(this.getTitle2()),
                    h3: TypographyHandler.Instance.toThemeOptions(this.getTitle3()),
                    h4: TypographyHandler.Instance.toThemeOptions(this.getTitle4()),
                    h5: TypographyHandler.Instance.toThemeOptions(this.getTitle5()),
                    h6: TypographyHandler.Instance.toThemeOptions(this.getTitle6()),
                    subtitle1: TypographyHandler.Instance.toThemeOptions(this.getSubTitle1()),
                    subtitle2: TypographyHandler.Instance.toThemeOptions(this.getSubTitle2()),
                    body1: TypographyHandler.Instance.toThemeOptions(this.getBody1()),
                    body2: TypographyHandler.Instance.toThemeOptions(this.getBody2()),
                    button: TypographyHandler.Instance.toThemeOptions(this.getButton()),
                    caption: TypographyHandler.Instance.toThemeOptions(this.getCaption()),
                    overline: TypographyHandler.Instance.toThemeOptions(this.getOverline()),
                }
            }
        );
    }
}