import { FontStyle, FontStyleValue } from 'data/app/Theme/Typography/Enum/FontStyle';
import { ApiHandler } from 'data/business/Base/ApiHandler';
import { ValueRenderer } from 'data/business/Base/ValueRenderer';
import { CSSProperties } from 'react';

export class FontStyleHandler extends ApiHandler<FontStyleValue, FontStyleValue> implements ValueRenderer<FontStyleValue> {
    public static Instance: FontStyleHandler = new FontStyleHandler();

    constructor() {
        super();
    }

    public toApi(value: FontStyleValue): FontStyleValue {
        return value;
    }

    public render(value: FontStyleValue): string {
        return FontStyle.Instance.labelFor(value)!;
    }

    public fromApi(value: FontStyleValue): FontStyleValue {
        return value;
    }

    public toCssProp(value?: FontStyleValue): CSSProperties['fontStyle'] {
        return value ? FontStyle.Instance.toCssProp(value) : undefined;
    }

    public fromCssProp(value: CSSProperties['fontStyle']): FontStyleValue | undefined {
        return FontStyle.Instance.fromCssProp(value);
    }

    public checkEquality(v1: FontStyleValue | undefined, v2: FontStyleValue | undefined): boolean {
        if (typeof v1 === 'undefined') {
            return typeof v2 === 'undefined';
        }
        if (typeof v2 === 'undefined') {
            return false;
        }

        return v1 === v2;
    }
}