import { LocaleSettingsContextValue } from 'app/context/localeSettings';
import { use12Hours } from 'lib/lang/use12Hours';
import { useMemo } from 'react';

const useLocaleSettingsContextData = (): LocaleSettingsContextValue => {
    const uses24hours = !use12Hours();

    return useMemo<LocaleSettingsContextValue>(
        () => ({ data: { uses24hours } }),
        [uses24hours]
    );
};

export default useLocaleSettingsContextData;