import { SimplePaletteColorOptions } from '@material-ui/core';
import { TextHandler } from 'data/business/TextHandler';
import { ColorIntention, ColorIntentionApiType, ColorIntentionCreateApiType, ColorIntentionFormType } from './ColorIntention.Types';

export class ColorIntentionHandler {
    public static Instance: ColorIntentionHandler = new ColorIntentionHandler();

    private constructor() {
    }

    public nullIfEquals(
        value: ColorIntentionFormType | undefined,
        reference: ColorIntention
    ): ColorIntentionCreateApiType | undefined {
        if (
            TextHandler.Instance.checkEquality(value?.light, reference.light) &&
            TextHandler.Instance.checkEquality(value?.main, reference.main) &&
            TextHandler.Instance.checkEquality(value?.dark, reference.dark)
        ) {
            return undefined;
        }

        return value ? this.toCreateApi(value) : undefined;
    }

    public fromApi(apiValue: ColorIntentionApiType): ColorIntention {
        return {
            light: apiValue.light,
            main: apiValue.main,
            dark: apiValue.dark
        };
    }

    public toApi(colorIntention: ColorIntention): ColorIntentionApiType {
        return {
            light: colorIntention.light,
            main: colorIntention.main,
            dark: colorIntention.dark
        };
    }

    public toThemeOptions(colorIntention: ColorIntention): SimplePaletteColorOptions {
        return {
            light: colorIntention.light,
            main: colorIntention.main,
            dark: colorIntention.dark
        };
    }

    public toNullableThemeOptions(
        formValue: ColorIntentionFormType | undefined,
        fallback: ColorIntentionFormType
    ): SimplePaletteColorOptions {
        if (!formValue || !formValue.main) {
            return {
                light: fallback.light,
                main: fallback.main!,
                dark: fallback.dark
            };
        }

        return {
            light: formValue.light,
            main: formValue.main!,
            dark: formValue.dark
        };
    }

    public toCreateApi(fromValue: ColorIntentionFormType): ColorIntentionCreateApiType {
        return {
            light: fromValue.light,
            main: fromValue.main!,
            dark: fromValue.dark
        };
    }

    public toUpdateFormType(colorIntention: ColorIntention): ColorIntentionFormType {
        return {
            light: colorIntention.light,
            main: colorIntention.main,
            dark: colorIntention.dark
        };
    }
}