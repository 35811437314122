import { getPlatformSettingsHeader } from 'app/api/platformSettings';
import { PlatformSettingsContextValue } from 'app/context/platformSettings';
import { PlatformSettingsHeader } from 'data/app/PlatformSettings/PlatformSettings.Types';
import { useCallback, useEffect, useMemo, useState } from 'react';

const usePlatformSettingsContextData = (
    initialPlatformSettings: PlatformSettingsHeader
): [PlatformSettingsHeader, PlatformSettingsContextValue] => {
    const [platformSettings, setPlatformSettings] = useState(initialPlatformSettings);
    useEffect(
        () => {
            setPlatformSettings(initialPlatformSettings);
        },
        [initialPlatformSettings]
    );

    const refresh = useCallback(
        () => getPlatformSettingsHeader().then((data) => setPlatformSettings(data)),
        [setPlatformSettings]
    );

    return useMemo<[PlatformSettingsHeader, PlatformSettingsContextValue]>(
        () => [platformSettings, { data: platformSettings, refresh }],
        [platformSettings, refresh]
    );
};

export default usePlatformSettingsContextData;