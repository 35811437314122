import { SelectItem } from 'data/app/SelectItem';
import { ColorPalette } from 'lib/display/Color';
import { Enum } from 'lib/lang/Enum';

export type TextColorValue =
    | 'Body1'
    | 'Body2'
    | 'Primary'
    | 'Secondary'
    | 'Tertiary'
    | 'Quaternary'
    | 'Quinquennial';

interface TextColorSelectItem extends SelectItem<TextColorValue> {
    color: ColorPalette;
}

export class TextColor extends Enum<TextColorValue, TextColorSelectItem> {
    public static Instance: TextColor = new TextColor();

    private constructor() {
        super([
                  { key: 'Body1', label: 'Couleur de corps 1', color: 'text.primary' },
                  { key: 'Body2', label: 'Couleur de corps 2', color: 'text.secondary' },
                  { key: 'Primary', label: 'Première couleur', color: 'primary' },
                  { key: 'Secondary', label: 'Deuxième couleur', color: 'secondary' },
                  { key: 'Tertiary', label: 'Troisième couleur', color: 'tertiary' },
                  { key: 'Quaternary', label: 'Quatrième couleur', color: 'quaternary' },
                  { key: 'Quinquennial', label: 'Cinquième couleur', color: 'quinquennial' }
              ]);
    }

    public toColor(textColor: TextColorValue): ColorPalette {
        return this.get(textColor)!.color;
    }
}