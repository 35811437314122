import { AppLoadingContextValue } from 'app/context/appLoading';
import { useMemo, useState } from 'react';

const useAppLoadingContextData = (): AppLoadingContextValue => {
    const [appLoading, setAppLoading] = useState<boolean>(false);

    return useMemo<AppLoadingContextValue>(
        () => ({
            loading: appLoading,
            setLoading: () => setAppLoading(true),
            unsetLoading: () => setAppLoading(false)
        }),
        [appLoading, setAppLoading]
    );
};

export default useAppLoadingContextData;