import { TextTransform, TextTransformValue } from 'data/app/Theme/Typography/Enum/TextTransform';
import { ApiHandler } from 'data/business/Base/ApiHandler';
import { ValueRenderer } from 'data/business/Base/ValueRenderer';
import { CSSProperties } from 'react';

export class TextTransformHandler extends ApiHandler<TextTransformValue, TextTransformValue> implements ValueRenderer<TextTransformValue> {
    public static Instance: TextTransformHandler = new TextTransformHandler();

    constructor() {
        super();
    }

    public toApi(value: TextTransformValue): TextTransformValue {
        return value;
    }

    public render(value: TextTransformValue): string {
        return TextTransform.Instance.labelFor(value)!;
    }

    public fromApi(value: TextTransformValue): TextTransformValue {
        return value;
    }

    public toCssProp(value?: TextTransformValue): CSSProperties['textTransform'] {
        return value ? TextTransform.Instance.toCssProp(value) : undefined;
    }

    public fromCssProp(value: CSSProperties['textTransform']): TextTransformValue | undefined {
        return TextTransform.Instance.fromCssProp(value);
    }

    public checkEquality(v1: TextTransformValue | undefined, v2: TextTransformValue | undefined): boolean {
        if (typeof v1 === 'undefined') {
            return typeof v2 === 'undefined';
        }
        if (typeof v2 === 'undefined') {
            return false;
        }

        return v1 === v2;
    }
}