import { SelectItem, SelectItems } from 'data/app/SelectItem';
import { Enum } from 'lib/lang/Enum';
import { CSSProperties } from 'react';

export type FontStyleValue = 'Normal'
    | 'Italic'
    | 'Oblique';

interface FontStyleSelectItem extends SelectItem<FontStyleValue> {
    cssProp: CSSProperties['fontStyle'];
}

export class FontStyle extends Enum<FontStyleValue, FontStyleSelectItem> {
    public static Instance: FontStyle = new FontStyle();

    private constructor() {
        super([
                  {
                      key: 'Normal',
                      label: 'Normal',
                      cssProp: 'normal'
                  },
                  {
                      key: 'Italic',
                      label: 'Italique',
                      cssProp: 'italic'
                  },
                  {
                      key: 'Oblique',
                      label: 'Oblique',
                      cssProp: 'oblique'
                  }
              ]);
    }

    public toCssProp(value: FontStyleValue): CSSProperties['fontStyle'] {
        return this.get(value)?.cssProp;
    }

    public fromCssProp(cssProp: CSSProperties['fontStyle']): FontStyleValue | undefined {
        return this.values().find((value) => value.cssProp === cssProp)?.key;
    }

    public cssValuesAsSelectItems(): SelectItems {
        return this.values().map((value) => ({ key: value.cssProp!, label: value.label }));
    }
}