import { Typography } from 'data/app/Theme/Typography/Typography.Types';
import { TypographyHandler } from 'data/app/Theme/Typography/TypographyHandler';
import { AMuiThemeCreator } from './AMuiThemeCreator';
import { ColorIntention } from './ColorIntention/ColorIntention.Types';
import { ColorIntentionHandler } from './ColorIntention/ColorIntentionHandler';
import { ThemeHeaderApiType } from './Theme.Types';

export class ThemeHeader extends AMuiThemeCreator {
    public readonly name: string;
    public readonly identifier: string;
    public readonly description?: string;
    public readonly enable: boolean;
    public readonly primary: ColorIntention;
    public readonly secondary: ColorIntention;
    public readonly tertiary: ColorIntention;
    public readonly quaternary: ColorIntention;
    public readonly quinquennial: ColorIntention;
    public readonly title1: Typography;
    public readonly title2: Typography;
    public readonly title3: Typography;
    public readonly title4: Typography;
    public readonly title5: Typography;
    public readonly title6: Typography;
    public readonly subTitle1: Typography;
    public readonly subTitle2: Typography;
    public readonly body1: Typography;
    public readonly body2: Typography;
    public readonly button: Typography;
    public readonly caption: Typography;
    public readonly overline: Typography;
    public readonly darkMode: boolean;
    public readonly lightMode: boolean;

    protected constructor(
        name: string,
        identifier: string,
        description: string | undefined,
        enable: boolean,
        primary: ColorIntention,
        secondary: ColorIntention,
        tertiary: ColorIntention,
        quaternary: ColorIntention,
        quinquennial: ColorIntention,
        title1: Typography,
        title2: Typography,
        title3: Typography,
        title4: Typography,
        title5: Typography,
        title6: Typography,
        subTitle1: Typography,
        subTitle2: Typography,
        body1: Typography,
        body2: Typography,
        button: Typography,
        caption: Typography,
        overline: Typography,
        darkMode: boolean,
        lightMode: boolean
    ) {
        super();
        this.name = name;
        this.identifier = identifier;
        this.description = description;
        this.enable = enable;
        this.primary = primary;
        this.secondary = secondary;
        this.tertiary = tertiary;
        this.quaternary = quaternary;
        this.quinquennial = quinquennial;
        this.title1 = title1;
        this.title2 = title2;
        this.title3 = title3;
        this.title4 = title4;
        this.title5 = title5;
        this.title6 = title6;
        this.subTitle1 = subTitle1;
        this.subTitle2 = subTitle2;
        this.body1 = body1;
        this.body2 = body2;
        this.button = button;
        this.caption = caption;
        this.overline = overline;
        this.darkMode = darkMode;
        this.lightMode = lightMode;
    }

    public static of(apiValue: ThemeHeaderApiType): ThemeHeader {
        return new ThemeHeader(
            apiValue.name,
            apiValue.identifier,
            apiValue.description,
            apiValue.enable,
            ColorIntentionHandler.Instance.fromApi(apiValue.primary),
            ColorIntentionHandler.Instance.fromApi(apiValue.secondary),
            ColorIntentionHandler.Instance.fromApi(apiValue.tertiary),
            ColorIntentionHandler.Instance.fromApi(apiValue.quaternary),
            ColorIntentionHandler.Instance.fromApi(apiValue.quinquennial),
            TypographyHandler.Instance.fromApi(apiValue.title1),
            TypographyHandler.Instance.fromApi(apiValue.title2),
            TypographyHandler.Instance.fromApi(apiValue.title3),
            TypographyHandler.Instance.fromApi(apiValue.title4),
            TypographyHandler.Instance.fromApi(apiValue.title5),
            TypographyHandler.Instance.fromApi(apiValue.title6),
            TypographyHandler.Instance.fromApi(apiValue.subTitle1),
            TypographyHandler.Instance.fromApi(apiValue.subTitle2),
            TypographyHandler.Instance.fromApi(apiValue.body1),
            TypographyHandler.Instance.fromApi(apiValue.body2),
            TypographyHandler.Instance.fromApi(apiValue.button),
            TypographyHandler.Instance.fromApi(apiValue.caption),
            TypographyHandler.Instance.fromApi(apiValue.overline),
            apiValue.darkMode,
            apiValue.lightMode,
        );
    }

    protected getPrimary(): ColorIntention {
        return this.primary;
    }

    protected getSecondary(): ColorIntention {
        return this.secondary;
    }

    protected getTertiary(): ColorIntention {
        return this.tertiary;
    }

    protected getQuaternary(): ColorIntention {
        return this.quaternary;
    }

    protected getQuinquennial(): ColorIntention {
        return this.quinquennial;
    }

    protected getBody1(): Typography {
        return this.body1;
    }

    protected getBody2(): Typography {
        return this.body2;
    }

    protected getButton(): Typography {
        return this.button;
    }

    protected getCaption(): Typography {
        return this.caption;
    }

    protected getOverline(): Typography {
        return this.overline;
    }

    protected getSubTitle1(): Typography {
        return this.subTitle1;
    }

    protected getSubTitle2(): Typography {
        return this.subTitle2;
    }

    protected getTitle1(): Typography {
        return this.title1;
    }

    protected getTitle2(): Typography {
        return this.title2;
    }

    protected getTitle3(): Typography {
        return this.title3;
    }

    protected getTitle4(): Typography {
        return this.title4;
    }

    protected getTitle5(): Typography {
        return this.title5;
    }

    protected getTitle6(): Typography {
        return this.title6;
    }
}