import { TypographyStyleOptions } from '@material-ui/core/styles/createTypography';
import { FontStyleHandler } from 'data/app/Theme/Typography/Enum/FontStyleHandler';
import { TextTransformHandler } from 'data/app/Theme/Typography/Enum/TextTransformHandler';
import { Typography, TypographyApiType, TypographyCreateApiType, TypographyFormType } from 'data/app/Theme/Typography/Typography.Types';
import { NumericHandler } from 'data/business/NumericHandler';
import { TextColorHandler } from 'data/business/Screen/Part/Enums/TextColorHandler';
import { TextHandler } from 'data/business/TextHandler';
import { isNil } from 'lodash';

export class TypographyHandler {
    public static Instance: TypographyHandler = new TypographyHandler();

    private constructor() {
    }

    public fromApi(apiValue: TypographyApiType): Typography {
        return {
            font: apiValue.font.name,
            fontSize: apiValue.fontSize,
            fontStyle: FontStyleHandler.Instance.toCssProp(apiValue.fontStyle),
            fontWeight: apiValue.fontWeight,
            lineHeight: apiValue.lineHeight,
            letterSpacing: apiValue.letterSpacing,
            textTransform: TextTransformHandler.Instance.toCssProp(apiValue.textTransform),
            textColor: TextColorHandler.Instance.fromApi(apiValue.textColor)
        };
    }

    public toApi(value: Typography): TypographyApiType {
        return {
            font: { name: value.font },
            fontSize: value.fontSize,
            fontStyle: FontStyleHandler.Instance.fromCssProp(value.fontStyle),
            fontWeight: value.fontWeight,
            lineHeight: value.lineHeight,
            letterSpacing: value.letterSpacing,
            textTransform: TextTransformHandler.Instance.fromCssProp(value.textTransform),
            textColor: TextColorHandler.Instance.toApi(value.textColor)
        };
    }

    public toThemeOptions(value: Typography): TypographyStyleOptions {
        return {
            fontFamily: value.font,
            fontSize: `${value.fontSize}rem`,
            fontStyle: value.fontStyle,
            fontWeight: value.fontWeight,
            lineHeight: value.lineHeight,
            letterSpacing: `${value.letterSpacing}em`,
            textTransform: value.textTransform
        };
    }

    public toNullableThemeOptions(formValue: TypographyFormType | undefined): TypographyStyleOptions | undefined {
        if (!formValue || !formValue.font || !formValue.fontSize) {
            return undefined;
        }

        return {
            fontFamily: formValue.font!,
            fontSize: `${formValue.fontSize!}rem`,
            fontStyle: FontStyleHandler.Instance.toCssProp(formValue.fontStyle),
            fontWeight: formValue.fontWeight,
            lineHeight: formValue.lineHeight,
            letterSpacing: isNil(formValue.letterSpacing) ? undefined : `${formValue.letterSpacing}em`,
            textTransform: TextTransformHandler.Instance.toCssProp(formValue.textTransform)
        };
    }

    public toCreateApi(formValue: TypographyFormType): TypographyCreateApiType {
        return {
            font: formValue.font!,
            fontSize: formValue.fontSize!,
            fontStyle: formValue.fontStyle,
            fontWeight: formValue.fontWeight,
            lineHeight: formValue.lineHeight,
            letterSpacing: formValue.letterSpacing,
            textTransform: formValue.textTransform,
            textColor: formValue.textColor!
        };
    }

    public toUpdateFormType(value: Typography): TypographyFormType {
        return {
            font: value.font,
            fontSize: value.fontSize,
            fontStyle: FontStyleHandler.Instance.fromCssProp(value.fontStyle),
            fontWeight: value.fontWeight,
            lineHeight: value.lineHeight,
            letterSpacing: value.letterSpacing,
            textTransform: TextTransformHandler.Instance.fromCssProp(value.textTransform),
            textColor: value.textColor
        };
    }

    public nullIfEquals(
        value: TypographyFormType | undefined,
        reference: Typography
    ): TypographyCreateApiType | undefined {
        if (
            TextHandler.Instance.checkEquality(value?.font, reference.font) &&
            NumericHandler.Instance.checkEquality(value?.fontSize, reference.fontSize) &&
            TextHandler.Instance.checkEquality(
                value?.fontStyle,
                FontStyleHandler.Instance.fromCssProp(reference.fontStyle)
            ) &&
            NumericHandler.Instance.checkEquality(value?.fontWeight, reference.fontWeight) &&
            NumericHandler.Instance.checkEquality(value?.lineHeight, reference.lineHeight) &&
            NumericHandler.Instance.checkEquality(value?.letterSpacing, reference.letterSpacing) &&
            TextHandler.Instance.checkEquality(
                value?.textTransform,
                TextTransformHandler.Instance.fromCssProp(reference.textTransform)
            ) &&
            TextColorHandler.Instance.checkEquality(value?.textColor, reference.textColor)
        ) {
            return undefined;
        }

        return value ? this.toCreateApi(value) : undefined;
    }
}