import { ApiHandler } from './Base/ApiHandler';
import { ValueRenderer } from './Base/ValueRenderer';

export class NumericHandler extends ApiHandler<number, number> implements ValueRenderer<number> {
    public static Instance: NumericHandler = new NumericHandler();

    private constructor() {
        super();
    }

    public fromApi(value: number): number {
        return value;
    }

    public render(value: number): string {
        return value.toLocaleString(
            undefined,
            { maximumFractionDigits: 20, maximumSignificantDigits: 21 }
        );
    }

    public toApi(value: number): number {
        return value;
    }

    public checkEquality(v1: number | undefined, v2: number | undefined): boolean {
        if (typeof v1 === 'undefined') {
            return typeof v2 === 'undefined';
        }
        if (typeof v2 === 'undefined') {
            return false;
        }

        return v1 === v2;
    }
}