import moment from 'moment';
import 'moment-locale-fr';
import * as tz from 'moment-timezone';

export const bootstrapMoment = () => {
    moment.locale('fr');
    moment.updateLocale('fr', {
        relativeTime: {
            future: 'dans %s',
            past: 'il y a %s',
            s: 'quelques secondes',
            ss: '%d secondes',
            m: 'une minute',
            mm: '%d minutes',
            h: 'une heure',
            hh: '%d heures',
            d: 'un jour',
            dd: '%d jours',
            M: 'un mois',
            MM: '%d mois',
            y: 'une année',
            yy: '%d ans'
        }
    });

    // @ts-ignore
    tz.defineLocale('fr', moment.localeData()._config);
    tz.locale('fr');
};