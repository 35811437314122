import { SelectItem } from 'data/app/SelectItem';

export abstract class Enum<T, SI extends SelectItem<T> = SelectItem<T>> {
    private readonly _values: SI[];

    protected constructor(values: SI[]) {
        this._values = values;
    }

    public values(): SI[] {
        return this._values;
    }

    public labelFor(value: T): string | null {
        const filtered = this._values.filter((val) => val.key === value);
        return (!filtered || !filtered.length) ? null : filtered[0].label;
    }

    protected get(value: T): SI | null {
        const filtered = this._values.filter((val) => val.key === value);
        return (!filtered || !filtered.length) ? null : filtered[0];
    }
}