import { HideMenuContextValue } from 'app/context/hideMenu';
import { noop } from 'lib/lang/noop';
import { useMemo, useState } from 'react';

const useHideMenuContextData = (initialHideMenu: boolean, forcedHideMenu: boolean): HideMenuContextValue => {
    const [hideMenu, setHideMenu] = useState<boolean>(initialHideMenu);

    return useMemo<HideMenuContextValue>(
        () => ({
            data: hideMenu,
            setHideMenu: forcedHideMenu ? noop : setHideMenu
        }),
        [hideMenu, setHideMenu, forcedHideMenu]
    );
};

export default useHideMenuContextData;