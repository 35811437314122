import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

export interface UserAgent {
    isMobile: boolean;
    isTablet: boolean;
}

export enum ScreenSize {
    Mobile,
    Tablet,
    Desktop,
    None
}

export const getScreenSize = (ua: UserAgent = { isMobile: false, isTablet: false }): ScreenSize => {
    if (ua.isMobile) {
        return ScreenSize.Mobile;
    } else if (ua.isTablet) {
        return ScreenSize.Tablet;
    } else {
        return ScreenSize.Desktop;
    }
};

export const screenSizeToBreakpoint = (screenSize: ScreenSize): Breakpoint | undefined => {
    switch (screenSize) {
        case ScreenSize.Mobile:
            return 'xs';
        case ScreenSize.Tablet:
            return 'sm';
        case ScreenSize.Desktop:
            return 'xl';
        case ScreenSize.None:
        default:
            return undefined;
    }
};