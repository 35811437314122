import { ScreenSize } from 'data/app/ScreenSize';
import { createCtx } from 'lib/context/context';

export interface ServerSideScreenSizeContextValue {
    size: ScreenSize;
}

const serverSideScreenSizeCtx = createCtx<ServerSideScreenSizeContextValue>();

export const useServerSideScreenSizeData = serverSideScreenSizeCtx[0];
export const ServerSideScreenSizeProvider = serverSideScreenSizeCtx[1];