import { darken, getContrastRatio, lighten, SimplePaletteColorOptions, Theme } from '@material-ui/core';
import { dark, light, PaletteColor, PaletteTonalOffset } from '@material-ui/core/styles/createPalette';
import { isNumber } from 'lodash';

export type ColorPalette =
    | 'text.primary'
    | 'text.secondary'
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'quaternary'
    | 'quinquennial';

export type Color =
    ColorPalette
    | 'error'
    | 'warning'
    | 'info'
    | 'success';

export type ColorSpecific = 'primary.light' | 'primary.main' | 'primary.dark' |
    'secondary.light' | 'secondary.main' | 'secondary.dark' |
    'tertiary.light' | 'tertiary.main' | 'tertiary.dark' |
    'quaternary.light' | 'quaternary.main' | 'quaternary.dark' |
    'quinquennial.light' | 'quinquennial.main' | 'quinquennial  .dark' |
    'error.light' | 'error.main' | 'error.dark' |
    'warning.light' | 'warning.main' | 'warning.dark' |
    'info.light' | 'info.main' | 'info.dark' |
    'success.light' | 'success.main' | 'success.dark' |
    'text.primary' | 'text.secondary' | 'text.disabled' | 'text.hint';

export const getColorSpecific = (color: Color): ColorSpecific => {
    if (color === 'text.primary' || color === 'text.secondary') {
        return color;
    }
    return `${color}.main` as ColorSpecific;
};

const getContrastText = (background: string, contrastThreshold: number) => {
    const contrastText =
        getContrastRatio(background, dark.text.primary) >= contrastThreshold
            ? dark.text.primary
            : light.text.primary;

    if (process.env.NODE_ENV !== 'production') {
        const contrast = getContrastRatio(background, contrastText);
        if (contrast < 3) {
            console.error(
                [
                    `Material-UI: The contrast ratio of ${contrast}:1 for ${contrastText} on ${background}`,
                    'falls below the WCAG recommended absolute minimum contrast ratio of 3:1.',
                    'https://www.w3.org/TR/2008/REC-WCAG20-20081211/#visual-audio-contrast-contrast',
                ].join('\n'),
            );
        }
    }

    return contrastText;
};

const addLightOrDark = (
    intent: SimplePaletteColorOptions,
    direction: 'light' | 'dark',
    tonalOffset: PaletteTonalOffset
) => {
    const tonalOffsetLight = isNumber(tonalOffset) ? tonalOffset : tonalOffset.light;
    const tonalOffsetDark = isNumber(tonalOffset) ? tonalOffset * 1.5 : tonalOffset.dark;

    if (!intent[direction]) {
        if (direction === 'light') {
            intent.light = lighten(intent.main, tonalOffsetLight);
        } else if (direction === 'dark') {
            intent.dark = darken(intent.main, tonalOffsetDark);
        }
    }
};

export const augmentColor = (
    color: SimplePaletteColorOptions,
    theme: Theme
): PaletteColor => {
    color = { ...color };
    if (!color.main) {
        throw new Error(
            [
                'Material-UI: The color provided to augmentColor(color) is invalid.',
                'The color object needs to have a "main" property',
            ].join('\n'),
        );
    }

    addLightOrDark(color, 'light', theme.palette.tonalOffset);
    addLightOrDark(color, 'dark', theme.palette.tonalOffset);
    if (!color.contrastText) {
        color.contrastText = getContrastText(color.main, theme.palette.contrastThreshold);
    }

    return color as PaletteColor;
};