import { empty } from 'lib/lang/empty';
import { isNil } from 'lodash';

export abstract class ApiHandler<Type, ApiType> {
    public abstract toApi(value: Type): ApiType;

    public toNullableApi(value: Type | undefined): ApiType | undefined {
        if (empty(value)) {
            return undefined;
        }

        return this.toApi(value);
    }

    public abstract fromApi(value: ApiType): Type;

    public fromNullableApi(value: ApiType | undefined): Type | undefined {
        if (isNil(value)) {
            return undefined;
        }

        return this.fromApi(value);
    }

    public abstract checkEquality(v1: Type | undefined, v2: Type | undefined): boolean;

    public nullIfEqual(value: Type | undefined, reference: Type | undefined): ApiType | undefined {
        if (this.checkEquality(value, reference)) {
            return undefined;
        }

        return this.toNullableApi(value);
    }
}