import { AppSettingsContextValue } from 'app/context/appSettings';
import { useMemo } from 'react';

const useAppSettingsContextData = (
    version: string
): AppSettingsContextValue => {
    return useMemo<AppSettingsContextValue>(
        () => ({ data: { version } }),
        [version]
    );
};

export default useAppSettingsContextData;