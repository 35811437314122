import { CalistaTheme } from 'data/app/Theme/Theme.Types';
import { AssetRouteQueryProps } from 'expressApp/routes/asset';
import * as qs from 'qs';
import React from 'react';
import Helmet from 'react-helmet';

export interface FavIconProps {
    theme: CalistaTheme;
}

const FavIcon = (
    {
        theme
    }: FavIconProps
) => {
    const query: AssetRouteQueryProps = {
        path: theme.assets.filled.ruptur,
        color: theme.palette.primary.main,
        mode: 'fill'
    };

    return (
        <Helmet>
            <link
                rel="icon"
                type="image/svg+xml"
                href={`/_p/__int/asset?${qs.stringify(query)}`}
            />
        </Helmet>
    );
};

export default FavIcon;