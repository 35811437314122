import { ThemeFormType } from 'data/app/Theme/Theme.Types';
import { ThemeHeader } from 'data/app/Theme/ThemeHeader';
import { ApiHandler } from 'data/business/Base/ApiHandler';
import { ValueRenderer } from 'data/business/Base/ValueRenderer';
import { ColorPalette, getColorSpecific } from 'lib/display/Color';
import { get } from 'lodash';
import { TextColor, TextColorValue } from './TextColor';

export class TextColorHandler extends ApiHandler<TextColorValue, TextColorValue> implements ValueRenderer<TextColorValue> {
    public static Instance: TextColorHandler = new TextColorHandler();

    private constructor() {
        super();
    }

    public checkEquality(v1: TextColorValue | undefined, v2: TextColorValue | undefined): boolean {
        if (typeof v1 === 'undefined') {
            return typeof v2 === 'undefined';
        }
        if (typeof v2 === 'undefined') {
            return false;
        }

        return v1 === v2;
    }

    public fromApi(value: TextColorValue): TextColorValue {
        return value;
    }

    public render(value: TextColorValue): string {
        return TextColor.Instance.labelFor(value)!;
    }

    public toApi(value: TextColorValue): TextColorValue {
        return value;
    }

    public toColor(value: TextColorValue): ColorPalette {
        return TextColor.Instance.toColor(value);
    }

    public toCssColor(value: TextColorValue, palette: ThemeHeader | ThemeFormType, mode: 'light' | 'dark'): string {
        if (value === 'Body1') {
            return mode === 'light'
                ? 'rgba(0, 0, 0, 0.87)'
                : '#fff';
        } else if (value === 'Body2') {
            return mode === 'light'
                ? 'rgba(0, 0, 0, 0.54)'
                : 'rgba(255, 255, 255, 0.7)';
        }

        return get(palette, getColorSpecific(this.toColor(value)));
    }
}