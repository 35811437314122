import { SelectItem, SelectItems } from 'data/app/SelectItem';
import { Enum } from 'lib/lang/Enum';
import { CSSProperties } from 'react';

export type TextTransformValue = 'Capitalize'
    | 'Uppercase'
    | 'Lowercase'
    | 'None'
    | 'FullWidth'
    | 'FullSizeKana';

interface TextTransformSelectItem extends SelectItem<TextTransformValue> {
    cssProp: CSSProperties['textTransform'];
}

export class TextTransform extends Enum<TextTransformValue, TextTransformSelectItem> {
    public static Instance: TextTransform = new TextTransform();

    private constructor() {
        super([
                  {
                      key: 'Capitalize',
                      label: 'Capitaliser',
                      cssProp: 'capitalize'
                  },
                  {
                      key: 'Uppercase',
                      label: 'Majuscule',
                      cssProp: 'uppercase'
                  },
                  {
                      key: 'Lowercase',
                      label: 'Minuscule',
                      cssProp: 'lowercase'
                  },
                  {
                      key: 'None',
                      label: 'Aucune',
                      cssProp: 'none'
                  },
                  {
                      key: 'FullWidth',
                      label: 'Pleine largeur',
                      cssProp: 'full-width'
                  },
                  {
                      key: 'FullSizeKana',
                      label: 'Taille réelle (Kana)',
                      cssProp: 'full-size-kana'
                  }
              ]);
    }

    public toCssProp(value: TextTransformValue): CSSProperties['textTransform'] {
        return this.get(value)?.cssProp;
    }

    public fromCssProp(cssProp: CSSProperties['textTransform']): TextTransformValue | undefined {
        return this.values().find((value) => value.cssProp === cssProp)?.key;
    }

    public cssValuesAsSelectItems(): SelectItems {
        return this.values().map((value) => ({ key: value.cssProp!, label: value.label }));
    }
}