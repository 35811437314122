import { isBoolean, isEmpty, isNil, isNumber, isString, trim } from 'lodash';

export const empty = (v: any): v is null | undefined => {
    if (isNumber(v) || isBoolean(v)) {
        return isNil(v);
    } else if (isString(v)) {
        return isEmpty(trim(v));
    }

    return isEmpty(v);
};