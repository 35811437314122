import { BreadcrumbContextValue } from 'app/context/breadcrumb';
import { Crumb } from 'data/app/Crumb';
import { noop } from 'lib/lang/noop';
import { useMemo, useState } from 'react';

const useBreadcrumbContextData = (
    initialCrumbs: Crumb[] = [],
    forcedBreadcrumb: boolean = false
): BreadcrumbContextValue => {
    const [breadcrumb, setBreadcrumb] = useState<Crumb[]>(initialCrumbs);

    return useMemo<BreadcrumbContextValue>(
        () => ({
            data: breadcrumb,
            setBreadcrumb: forcedBreadcrumb ? noop : setBreadcrumb
        }),
        [breadcrumb, setBreadcrumb, forcedBreadcrumb]
    );
};

export default useBreadcrumbContextData;