import { ActionDataContextValue } from 'app/context/actionData';
import { noop } from 'lib/lang/noop';
import { ButtonListElements } from 'lib/layout/Buttons/ButtonList.Props';
import { useMemo, useState } from 'react';

const useActionContextData = (
    initialActions: ButtonListElements = [],
    forcedActions: boolean = false
): ActionDataContextValue => {
    const [actions, setActions] = useState<ButtonListElements>(initialActions);

    return useMemo<ActionDataContextValue>(
        () => ({
            data: actions,
            setData: forcedActions ? noop : setActions
        }),
        [actions, setActions, forcedActions]
    );
};

export default useActionContextData;