import { ThemeHeaderHandler } from 'data/app/Theme/ThemeHeaderHandler';
import { BooleanHandler } from 'data/business/BooleanHandler';
import { DateTimeHandler } from 'data/business/DateTimeHandler';
import { TextHandler } from 'data/business/TextHandler';
import { UserHeaderHandler } from 'data/business/User/UserHeaderHandler';
import { isEmpty } from 'lodash';
import { PlatformSettings, PlatformSettingsApiType, PlatformSettingsFormType, PlatformSettingsUpdateApiType } from './PlatformSettings.Types';

export class PlatformSettingsHandler {
    public static Instance: PlatformSettingsHandler = new PlatformSettingsHandler();

    private constructor() {
    }

    public fromApi(apiValue: PlatformSettingsApiType): PlatformSettings {
        return {
            name: apiValue.name,
            description: apiValue.description,
            theme: ThemeHeaderHandler.Instance.fromApi(apiValue.theme),
            darkMode: apiValue.darkMode,
            modification: DateTimeHandler.Instance.fromApi(apiValue.modification),
            modificator: UserHeaderHandler.Instance.fromApi(apiValue.modificator)
        };
    }

    public toUpdateFormType(platformSettings: PlatformSettings): PlatformSettingsFormType {
        return {
            name: platformSettings.name,
            description: platformSettings.description,
            themeIdentifier: platformSettings.theme.identifier,
            darkMode: platformSettings.darkMode
        };
    }

    public toUpdateApi(
        formValue: PlatformSettingsFormType,
        reference: PlatformSettings
    ): PlatformSettingsUpdateApiType {
        return {
            name: TextHandler.Instance.nullIfEqual(formValue.name!, reference.name),
            description: TextHandler.Instance.nullIfEqual(formValue.description, reference.description),
            deleteDescription: isEmpty(formValue.description),
            themeIdentifier: TextHandler.Instance.nullIfEqual(formValue.themeIdentifier!, reference.theme.identifier),
            darkMode: BooleanHandler.Instance.nullIfEqual(formValue.darkMode, reference.darkMode)
        };
    }
}