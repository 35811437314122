import React from 'react';
import Loadable from 'react-loadable';

const AuthFrame = Loadable.Map(
    {
        loader: {
            controller: () => import('./AuthFrame.Controller'),
            view: () => import('./AuthFrame.View')
        },
        loading: () => null,
        render: (loaded) => <loaded.controller.default view={loaded.view.default}/>
    }
);

export default AuthFrame;