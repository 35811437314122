import { ApiHandler } from './Base/ApiHandler';
import { ValueRenderer } from './Base/ValueRenderer';

export class BooleanHandler extends ApiHandler<boolean, boolean> implements ValueRenderer<boolean> {
    public static Instance: BooleanHandler = new BooleanHandler();

    private constructor() {
        super();
    }

    public fromApi(value: boolean): boolean {
        return value;
    }

    public render(value: boolean): string {
        return value ? 'Oui' : 'Non';
    }

    public toApi(value: boolean): boolean {
        return value;
    }

    public checkEquality(v1: boolean | undefined, v2: boolean | undefined): boolean {
        if (typeof v1 === 'undefined') {
            return typeof v2 === 'undefined';
        }
        if (typeof v2 === 'undefined') {
            return false;
        }

        return v1 === v2;
    }
}