import { ConnectedUserContextValue } from 'app/context/connectedUser';
import { AuthData } from 'data/app/AuthData';
import { ConnectedUser, UserInfo } from 'data/app/ConnectedUser';
import { pick } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

const useConnectedUserContextData = (
    authData: AuthData,
    userPersistor: (data: UserInfo | null) => void
): ConnectedUserContextValue => {
    const [connectedUserData, setConnectedUserData] = useState<ConnectedUser>(authData.user || {
        userGuid: null,
        refreshTokenGuid: null
    });
    const setUserData = useCallback(
        (data: ConnectedUser) => {
            setConnectedUserData(data);
            userPersistor(
                data.userGuid == null || data.refreshTokenGuid == null
                    ? null
                    : pick(data, 'userGuid', 'refreshTokenGuid')
            );
        },
        [userPersistor]
    );
    const unsetUserData = useCallback(
        () => {
            setConnectedUserData({
                                     userGuid: null,
                                     refreshTokenGuid: null
                                 });
            userPersistor(null);
        },
        [userPersistor]
    );

    return useMemo<ConnectedUserContextValue>(
        () => ({
            data: connectedUserData,
            setData: setUserData,
            unsetData: unsetUserData
        }),
        [connectedUserData, setUserData, unsetUserData]
    );
};

export default useConnectedUserContextData;