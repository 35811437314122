import { createCtx } from 'lib/context/context';

export interface HideMenuContextValue {
    data: boolean;
    setHideMenu: (hideMenu: boolean) => void;
}

const hideMenuCtx = createCtx<HideMenuContextValue>();

export const useHideMenu = hideMenuCtx[0];
export const HideMenuProvider = hideMenuCtx[1];