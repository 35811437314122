import { ThemeHeaderHandler } from 'data/app/Theme/ThemeHeaderHandler';
import { PlatformSettingsHeader, PlatformSettingsHeaderApiType } from './PlatformSettings.Types';

export class PlatformSettingsHeaderHandler {
    public static Instance: PlatformSettingsHeaderHandler = new PlatformSettingsHeaderHandler();

    private constructor() {
    }

    public fromApi(apiValue: PlatformSettingsHeaderApiType): PlatformSettingsHeader {
        return {
            name: apiValue.name,
            description: apiValue.description,
            theme: ThemeHeaderHandler.Instance.fromApi(apiValue.theme),
            darkMode: apiValue.darkMode
        };
    }

    public toApi(platformSettingsHeader: PlatformSettingsHeader): PlatformSettingsHeaderApiType {
        return {
            name: platformSettingsHeader.name,
            description: platformSettingsHeader.description,
            theme: ThemeHeaderHandler.Instance.toApi(platformSettingsHeader.theme),
            darkMode: platformSettingsHeader.darkMode
        };
    }
}