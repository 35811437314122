import { InitialDataContextValue } from 'app/context/initialData';
import { InitialData } from 'data/app/InitialData';
import { useMemo } from 'react';

const useInitialDataContextData = (
    initialData?: InitialData
): InitialDataContextValue => {
    return useMemo<InitialDataContextValue>(
        () => ({ data: initialData }),
        [initialData]
    );
};

export default useInitialDataContextData;