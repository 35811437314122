import { createCtx } from 'lib/context/context';

export interface AppLoadingContextValue {
    loading: boolean;
    setLoading: () => void;
    unsetLoading: () => void;
}

const appLoadingCtx = createCtx<AppLoadingContextValue>();

export const useAppLoading = appLoadingCtx[0];
export const AppLoadingProvider = appLoadingCtx[1];