import { Guid } from 'guid-typescript';
import { UserHeader, UserHeaderApi } from './User.Types';

export class UserHeaderHandler {
    public static Instance: UserHeaderHandler = new UserHeaderHandler();

    private constructor() {
    }

    public fromApi(value: UserHeaderApi): UserHeader {
        return {
            guid: Guid.parse(value.guid),
            name: value.name,
            email: value.email
        };
    }

    public fromNullableApi(apiValue?: UserHeaderApi): UserHeader | undefined {
        if (typeof apiValue === 'undefined' || apiValue === null) {
            return undefined;
        }

        return this.fromApi(apiValue);
    }
}