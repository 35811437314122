import { Moment } from 'moment';
import { tz } from 'moment-timezone';
import { ApiHandler } from './Base/ApiHandler';
import { ValueRenderer } from './Base/ValueRenderer';

export class DateTimeHandler extends ApiHandler<Moment, string> implements ValueRenderer<Moment> {
    public static Instance: DateTimeHandler = new DateTimeHandler();
    public static readonly API_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss[,]SSSSSSSSSZ[ ]';

    private constructor() {
        super();
    }

    public render(date: Moment): string {
        return date.calendar(undefined, {
            lastDay: '[Hier à] LT',
            sameDay: '[Aujourd\'hui à] LT',
            nextDay: '[Demain à] LT',
            lastWeek: 'dddd [dernier à] LT',
            nextWeek: 'dddd [à] LT',
            sameElse: 'LL [à] LT'
        });
    }

    public toApi(date: Moment): string {
        return date.format(DateTimeHandler.API_FORMAT) + tz.guess();
    }

    public fromApi(api: string): Moment {
        const [dateValue, timezone] = api.split(' ');
        // @ts-ignore
        return tz(dateValue, timezone).tz(tz.guess());
    }

    public checkEquality(v1: Moment | undefined, v2: Moment | undefined): boolean {
        if (typeof v1 === 'undefined') {
            return typeof v2 === 'undefined';
        }
        if (typeof v2 === 'undefined') {
            return false;
        }

        return v1.isSame(v2);
    }

}