import { isEmpty, trim } from 'lodash';
import { ApiHandler } from './Base/ApiHandler';
import { ValueRenderer } from './Base/ValueRenderer';

export class TextHandler extends ApiHandler<string, string> implements ValueRenderer<string> {
    public static Instance: TextHandler = new TextHandler();

    private constructor() {
        super();
    }

    public fromApi(value: string): string {
        return value;
    }

    public fromNullableApi(value: string | undefined): string | undefined {
        if (isEmpty(value)) {
            return undefined;
        }

        return value;
    }

    public render(value: string): string {
        return value;
    }

    public toApi(value: string): string {
        return value;
    }

    public toNullableApi(value: string | undefined): string | undefined {
        if (isEmpty(trim(value))) {
            return undefined;
        }
        return value;
    }

    public checkEquality(v1: string | undefined, v2: string | undefined): boolean {
        if (typeof v1 === 'undefined') {
            return typeof v2 === 'undefined';
        }
        if (typeof v2 === 'undefined') {
            return false;
        }

        return v1 === v2;
    }
}