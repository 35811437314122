import * as Sentry from '@sentry/browser';
import { ServerSideScreenSizeProvider } from 'app/context/serverSideScreenSize';
import axios from 'axios';
import 'bootstrap/fonts';
import { bootstrapMoment } from 'bootstrap/moment';
import { UserInfo } from 'data/app/ConnectedUser';
import { PlatformSettingsHeaderHandler } from 'data/app/PlatformSettings/PlatformSettingsHeaderHandler';
import { ScreenSize } from 'data/app/ScreenSize';
import 'moment-locale-fr';
import * as OfflinePluginRuntime from 'offline-plugin/runtime';
import qs from 'qs';
import React, { useCallback } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import { hydrate } from 'react-dom';
import Loadable from 'react-loadable';
import { BrowserRouter } from 'react-router-dom';

import App from './app/App';

const config = window.__ENV__;
const authData = window.__AUTH__;
const breadcrumb = window.__BREADCRUMB__;
const actions = window.__ACTIONS__;
const hideMenu = window.__HIDEMENU__;
const platformSettings = PlatformSettingsHeaderHandler.Instance.fromApi(window.__SETTINGS__);

if (process.env.NODE_ENV === 'production') {
    console.log('Installing service worker');
    OfflinePluginRuntime.install();
}

axios.defaults.baseURL = config.apiUrl;
axios.defaults.paramsSerializer = (params) => {
    return qs.stringify(params);
};

bootstrapMoment();

Sentry.init(
    {
        dsn: config.sentryDsn,
        environment: config.sentryEnv,
        debug: true,
        maxBreadcrumbs: 100
    }
);

const AppComponent = () => {
    const [, setCookie, removeCookie] = useCookies(['userData']);
    const userPersistor = useCallback(
        (data: UserInfo | null) => {
            if (data) {
                removeCookie('userData', { path: '/' });
                setCookie('userData', data, { path: '/', maxAge: 60 * 60 * 24 * 365 });
            } else {
                removeCookie('userData', { path: '/' });
            }
        },
        [setCookie, removeCookie]
    );

    return (
        <App
            initialBreadcrumb={breadcrumb}
            forcedBreadcrumb={false}
            initialActions={actions}
            forcedActions={false}
            initialHideMenu={hideMenu}
            forcedHideMenu={false}
            authData={authData}
            userPersistor={userPersistor}
            initialPlatformSettings={platformSettings}
            version={config.version}
        />
    );
};

Loadable.preloadReady().then(
    () => hydrate(
        <BrowserRouter>
            <ServerSideScreenSizeProvider value={{ size: ScreenSize.None }}>
                <CookiesProvider>
                    <AppComponent/>
                </CookiesProvider>
            </ServerSideScreenSizeProvider>
        </BrowserRouter>,
        document.getElementById('root'),
        () => {
            // [ReHydratation](https://github.com/cssinjs/jss/blob/master/docs/ssr.md)
            const jssStyles = document.getElementById('jss-ssr');
            if (jssStyles && jssStyles.parentNode) {
                jssStyles.parentNode.removeChild(jssStyles);
            }
        }
    )
);

if (module.hot) {
    module.hot.accept();
}
