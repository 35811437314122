import background from './background.jpg';
import dualLoading from './loading/dual.svg';
import eclipseLoading from './loading/eclipse.svg';
import infinityLoading from './loading/infinity.svg';
import favicon from './logo/favicon.png';
import logo_transparent from './logo/logo_transparent.png';
import menu from './menu.jpg';
import ruptur from './viz/ruptur.svg';
import undraw_access_denied_6w73 from './viz/undraw_access_denied_6w73.svg';
import undraw_add_content_d1tf from './viz/undraw_add_content_d1tf.svg';
import undraw_adjustments_p22m from './viz/undraw_adjustments_p22m.svg';
import undraw_click_here_2li1 from './viz/undraw_click_here_2li1.svg';
import undraw_create_f05x from './viz/undraw_create_f05x.svg';
import undraw_creativity_wqmm from './viz/undraw_creativity_wqmm.svg';
import undraw_empty_xct9 from './viz/undraw_empty_xct9.svg';
import undraw_mind_map_cwng from './viz/undraw_mind_map_cwng.svg';
import undraw_personal_site_xyd1 from './viz/undraw_personal_site_xyd1.svg';
import undraw_QA_engineers_dg5p from './viz/undraw_QA_engineers_dg5p.svg';
import undraw_settings_ii2j from './viz/undraw_settings_ii2j.svg';
import undraw_taking_notes_tjaf from './viz/undraw_taking_notes_tjaf.svg';
import undraw_things_to_say_ewwb from './viz/undraw_things_to_say_ewwb.svg';

export interface FilledAssets {
    ruptur: string;
    undraw_mind_map_cwng: string;
    undraw_click_here_2li1: string;
    undraw_personal_site_xyd1: string;
    undraw_taking_notes_tjaf: string;
    undraw_add_content_d1tf: string;
    undraw_create_f05x: string;
    undraw_empty_xct9: string;
    undraw_QA_engineers_dg5p: string;
    undraw_things_to_say_ewwb: string;
    undraw_creativity_wqmm: string;
    undraw_settings_ii2j: string;
    undraw_adjustments_p22m: string;
    undraw_access_denied_6w73: string;
    eclipseLoading: string;
}

export interface StrokedAssets {
    infinityLoading: string;
    dualLoading: string;
}

export interface Assets {
    background: string;
    menu: string;
    favicon: string;
    logo_transparent: string;
    filled: FilledAssets;
    stroked: StrokedAssets;
}

export default {
    background,
    menu,
    favicon,
    logo_transparent,
    filled: {
        ruptur,
        undraw_mind_map_cwng,
        undraw_click_here_2li1,
        undraw_personal_site_xyd1,
        undraw_taking_notes_tjaf,
        undraw_add_content_d1tf,
        undraw_create_f05x,
        undraw_empty_xct9,
        undraw_QA_engineers_dg5p,
        undraw_things_to_say_ewwb,
        undraw_creativity_wqmm,
        undraw_settings_ii2j,
        undraw_adjustments_p22m,
        undraw_access_denied_6w73,
        eclipseLoading
    },
    stroked: {
        infinityLoading,
        dualLoading
    }
};