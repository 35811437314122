import axios from 'axios';
import { PlatformSettings, PlatformSettingsApiType, PlatformSettingsHeader, PlatformSettingsHeaderApiType, PlatformSettingsUpdateApiType } from 'data/app/PlatformSettings/PlatformSettings.Types';
import { PlatformSettingsHandler } from 'data/app/PlatformSettings/PlatformSettingsHandler';
import { PlatformSettingsHeaderHandler } from 'data/app/PlatformSettings/PlatformSettingsHeaderHandler';
import { noop } from 'lib/lang/noop';

export const getPlatformSettings = (): Promise<PlatformSettings> =>
    axios.get<PlatformSettingsApiType>('App/AppSettings')
         .then((reponse) => PlatformSettingsHandler.Instance.fromApi(reponse.data));

export const getPlatformSettingsHeader = (): Promise<PlatformSettingsHeader> =>
    axios.get<PlatformSettingsHeaderApiType>('App/AppSettings/Header')
         .then((reponse) => PlatformSettingsHeaderHandler.Instance.fromApi(reponse.data));

export const updatePlatformSettings = (apiValue: PlatformSettingsUpdateApiType): Promise<void> =>
    axios.patch<void>('App/AppSettings', apiValue)
         .then(() => noop());